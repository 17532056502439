.loading-spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
marquee {
  background: #ee005f;
  color: white;
  position: relative;
  top: 160px;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


marquee h3{
margin-top:8px;
font-size:18px;
}
#refresh-image{
    filter:invert(1);
    width:20px
}
.date-col{
  background-color:rgb(19 26 62);color: white;font-size: 20px;
}
  
  th,
  td {
    /* height: 20px; */
    border: 1px solid black;
    border-top: 1px solid black !important;
    font-weight:bold;
  }
  .table-striped>tr:nth-of-type(odd) {
  background:#f7d5c8 !important;
}
  td{
    font-size: 32px;
    padding: 8px;
  }

  table {
    width: 100%;
    text-align: center;
    background-color: white
  }
  .time{
    font-size: 22px;
    text-align: center;
    position: absolute;
  left: 0;
  right: 0;
  }
  

  @import url('https://fonts.googleapis.com/css2?family=Smooch&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');

  body {
    margin: 0;
    white-space: pre-line;

  }

  header {
    position: fixed;
    width: 100%;
    top: -1px;
    background-color: blue;
    z-index: 2;
    height: 160px;
  }
  .timer-container{
    background-color: rgb(19, 26, 62);
    margin: 0 auto;
    color: white;
    font-weight: 700;
    padding: 20px;
    border-radius: 10px;
    padding-top: 108px;
    height: 95px;
    margin-top: 4px;
  }
  .timer-div{
    margin-top: -70px;
  }
  .timer-container h1,.timer-container h2,.timer-container h3{
    margin: 0;
  }
  #wrapper {
  display: flex;    
  width:100%;
  margin-top: -10px;
  }
  #wrapper .timebox {
      flex-basis: 100%;
  }
  .timebox{
    background-color: #ee005f;
    margin: 0 5px;
    height: 40px;
    position: relative;
    border-radius: 10px;
    font-size: 32px;
    text-align: center;
  }
  .timebox::after{
    font-size: 14px;
    position: absolute;
    padding-top: 5px;
    bottom: -20px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
  }
  .timebox#days::after{
    content: "Days";
  }
  .timebox#hours::after{
    content: "Hours";
  }
  .timebox#minutes::after{
    content: "Minutes";
  }
  .timebox#seconds::after{
    content: "Seconds";
  }
  .logo {
    font-family: smooch;
    color: white;
    font-size: 2.2em;
    margin: 0;
    float: left;
  margin-left: 10px;
  max-width:200px;
  margin-top:20px;
  }

  header nav button {
    background-color: transparent;
    border: 0;
    font-family: quicksand;
    color: white;
    text-transform: uppercase;
    font-size: 0.9em;
    cursor: pointer;
    transition-delay: 0s;
    transition-duration: 1s;
  }

  header nav button:hover {
    color: yellowgreen;
    transform: translate(0px, -4px);
    text-shadow: 0 6px 6px rgba(0, 0, 0, 1);
  }

  body {
    background-color: #bedcfa;
    color: #000;
  }
  
  .buttondiv{
  text-align: center;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
  padding: 4px 0;
  width:100%;
  background: rgb(19, 26, 62);
  }
  .buttondiv .btn{
  margin: 0px 5px !important;
  background: #ee005f;
  }

  .card {
    color: white;
    float: left;
    margin-left: 24px;
    margin-top: -23px;
  }

  .date {
    color: #353333;
    font-size: 1.6rem;
  }
  .history{
    color: white;
    text-decoration: none;
     margin-right: 24px;
     background: #ee005f;
     font-weight: 700;
    float: right;
  }
  .history:hover{
    background-color: rgb(19, 26, 62);
  }
  #demo{
    text-align: center;
    font-size: 24px;
    margin: 0;
    color: white;
    margin-left: 24px;
  /* float: left; */
  margin-top: -8px;
  background: #ee005f;
  padding: 10px;
  border-radius: 10px;
  }
  #refresh{
  position: absolute;
  top: -75px;
  right: 50%;
  }
  @media screen and (max-width: 767px) {
  
    marquee{
    background: #ee005f;
  color: white;
  position: relative;
  top: -72px;
  z-index: 11;
}
      .buttondiv .btn{
          font-size:10px !important;
           margin:0 2px !important;
      }
      #refresh{
      padding: 3px 11px;
          position: absolute;
              top: -110px;
                  right: 15%;
      }
  #chartbuttons{
    position: relative;
    z-index: 999;
    margin-top: -2px !important;
    top: -72px;
  }
  #chartbuttons a{
    font-size: 12px !important;
    padding: 5px 0 !important;
  }
  .timestamp{
    font-size: 14px !important;
  }
  .buttondiv{
      text-align: center;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
  }
   #hometable{
       margin-top:-70px;
   }
   .chart_table{
    margin-top:-70px !important;
   }
    td{
    font-size: 10px;
    padding: 8px 0px !important;
  }
  .timer-container{
    height: auto;
    margin-top: -20px;
  }
  
  .buttondiv{
      text-align: center;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
  }
  .timer-div h3{
    margin-bottom: 18px;
  }
  #wrapper{
    margin-bottom: 12px;
  }
  
header{
  position: relative;
  margin-top: -40px;
  height: 150px;
}
.logo{
  float: none;
  margin-top: 50px;
  margin-bottom: 25px;
}
.card h3{
  font-size: 16px;
}
.card{
  margin-left: 10px;
  margin-top: -93px;
}
#demo{
  margin-left: 10px;
  float: left;
  font-size: 16px;
  margin-top: -91px;
}
.history{
  margin-left: 10px;
  margin-top: -110px;
  float: left;
}
}